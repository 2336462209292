const form = document.getElementById('app-form-contact');
if (form && form !== null) {
  form.addEventListener('submit', (e) => {
    e.preventDefault();

    const data = new FormData(form);
    const data2 = {};
    data.forEach((value, key) => {
      data2[key] = value;
    });

    fetch('/mailer.php', {
      body: JSON.stringify(data2),
      method: 'POST',
    });

    form.classList.add('d-none');
    document.getElementById('app-form-contact-submit').classList.remove('d-none');
  });
}

const videos = document.querySelector('video');
if (videos && videos !== null) {
  videos.playbackRate = 0.75;
}
